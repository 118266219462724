<template>
	<div class="col-md-12">
        <tif-question></tif-question>
    </div>
</template>

<script>
    import TIFQuestion from './tif/question';

    export default {
        components:{
            "tif-question":TIFQuestion
        }  
    }
</script>


