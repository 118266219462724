<template>
	<div class="dashboard">
      <div class="dashboard-title">
        <span>Welcome to Dashboard</span>
      </div>
      <div class="dashboard-content">
        You are logged in!
      </div>
    </div>
</template>

<script>

    export default {
        
        
    }
</script>
