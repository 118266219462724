<template>
  <div class="col-md-12">
        <tif></tif>
    </div>
</template>

<script>
    import tif from './tif/tif';

    export default {
        components:{
            "tif":tif
        }  
    }
</script>
