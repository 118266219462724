<template>
  <div class="col-md-12">
        <category></category>
    </div>
</template>

<script>
    import Category from './license/Category';

    export default {
        components:{
            "category":Category
        }  
    }
</script>
