<template>
	<div class="col-md-12">
        <product></product>
    </div>
</template>

<script>
    import Products from './products/Products';

    export default {
        components:{
            "product":Products
        }  
    }
</script>
