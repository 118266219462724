<template>
	<div class="col-md-12">
        <question></question>
    </div>
</template>

<script>
    import Question from './questions/Question';

    export default {
        components:{
            "question":Question
        }  
    }
</script>