<template>
	<div class="col-md-12">
        <pages></pages>
    </div>
</template>

<script>
   import Pages from './pages/Pages';

    export default {
        components:{
            "pages":Pages
        }  
    }
</script>
